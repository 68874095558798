<template>
  <div>
    <router-link class="come-back" to="/admin/topic/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование актуальной темы №${$route.params.id}` : 'Создание актуальной темы' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createTopic" ref="createTopic">
      <div>
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="topicData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание<span>*</span></p>
          <input required placeholder="Введите описание" v-model="topicData.description" type="text" name="description" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required placeholder="Введите дату" v-model="topicData.date" type="date" name="date" class="input blue">
        </div>

        <div class="content-sb">
          <p>Превью<span>*</span></p>
          <custom-drop-zone :name="'photo'"
                            @setFile="setFile($event, ['topicData','photo'])"
                            :uploaded-blob="previewBlob"/>
        </div>

        <div class="content-sb">
          <p>Текст</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="topicData.text" class="wysiwyg"/>
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/topic" class="btn-white">Отмена</router-link>

        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomDropZone from '@/components/Admin/CustomDropZone'

export default {
  name: 'CreateTopic',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      topicData: {
        title: null,
        description: null,
        date: null,
        photo: null,
        text: null
      }
    }
  },

  watch: {
    '$store.getters.getDetailedTopic' () {
      const dataFromServer = this.$store.getters.getDetailedTopic

      this.topicData = {
        date: dataFromServer.date,
        photo: null,
        text: dataFromServer.text,
        title: dataFromServer.title,
        description: dataFromServer.description
      }
      this.previewBlob = dataFromServer.photo

      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('setDetailedTopic', this.$route.params.id)
    }
  },

  methods: {

    createTopic () {
      const formData = new FormData()

      for (const key in this.topicData) {
        if (this.topicData[key]) {
          formData.append(key, this.topicData[key])
        }
      }

      if (!this.isEdit) {
        this.$store.dispatch('createTopic', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('editTopic', {
          data: formData,
          id: this.$route.params.id
        })
      }

      console.debug(...formData)
    },

    setFile (file, key) {
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        this.previewBlob = null
        this[key[0]][key[1]] = file
      }
    }

  },

  components: {
    CustomDropZone,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
